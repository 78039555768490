.form-control-select {
  border-radius: 0.625rem;
  // box-shadow: 0 3px 4px rgba(0,0,0,.03);
  min-width: 120px;
  min-height: calc(1.5em + 1.55rem + 4px);
  .form-select.form-select-solid {
    background-color: var(--bs-light) !important;
    border-color: var(--bs-light) !important;
    color: var(--bs-light-inverse) !important;
    transition: color 0.2s ease;
    min-height: calc(1.5em + 1.55rem + 4px);
  }
}
[data-bs-theme='dark'] .form-control-select {
  border-radius: 0.625rem;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.03);
  min-width: 120px;
  min-height: calc(1.5em + 1.55rem + 4px);
  .form-select.form-select-solid {
    background-color: var(--bs-light) !important;
    border-color: var(--bs-light) !important;
    color: var(--bs-light-inverse) !important;
    min-height: calc(1.5em + 1.55rem + 4px);
  }
}
