.range-picker-custom{
    .ant-picker-range{
        border: 0px;
        width: 100%;
        border-radius: 0.625rem;
        
        font-weight: 600;
        // box-shadow: 0 3px 4px rgba(0,0,0,.03);
        min-height: calc(1.5em + 1.55rem + 4px);
        background-color: var(--bs-light) !important;
        border-color: var(--bs-light) !important;
        color: var(--bs-light-inverse) !important;
        & .ant-picker-input > input{
            color: var(--bs-light-inverse) !important;
        }
    }
    &.is-white{
        .ant-picker-range{
            background-color: #fff !important;
        }
    }
}
[data-bs-theme=dark] .range-picker-custom{
    .ant-picker-range{
        border: 0px;
        width: 100%;
        border-radius: 0.625rem;
        font-weight: 600;
        box-shadow: 0 3px 4px rgba(0,0,0,.03);
        min-height: calc(1.5em + 1.55rem + 4px);
        background-color: var(--bs-light) !important;
        border-color: var(--bs-light) !important;
        color: var(--bs-light-inverse) !important;
        & .ant-picker-input > input, .ant-picker-input > input::placeholder{
            color: var(--bs-light-inverse) !important;
        }
        & .ant-picker-range-separator > .ant-picker-separator{
            color: var(--bs-light-inverse) !important;
        }
    }
    .ant-picker-clear, .ant-picker-suffix{
        background-color: var(--bs-light) !important; 
        color: var(--bs-light-inverse) !important;
    }

}

[data-bs-theme=dark] .ant-picker-dropdown {
    .ant-picker-panel-container{
        background-color: var(--bs-light) !important;
        border-color: var(--bs-light) !important;
        color: var(--bs-light-inverse) !important;
    }
    .ant-picker-cell-in-view, .ant-picker-content th, .ant-picker-header, .ant-picker-header button{
        color: var(--bs-light-inverse) !important;
    }
    .ant-picker-range-arrow::before{
        background-color: var(--bs-light) !important;
    }
}