//
// Toolbar
//

// Form controls
:is([data-kt-app-layout='light-sidebar'], [data-kt-app-layout='light-header'], [data-kt-app-layout='dark-header']) {
  .app-toolbar {
    .form-select.form-select {
      background-color: var(--#{$prefix}body-bg) !important;
    }
  }
}

// Desktop mode
@include media-breakpoint-down(xl) {
  .app-toolbar {
    body:not([data-kt-app-toolbar-fixed='true']) & {
      height: auto;
      background-color: transparent;
      border-top: 0;
      border-bottom: 0;
      box-shadow: none;
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .app-toolbar {
    body:not([data-kt-app-toolbar-fixed-mobile='true']) & {
      height: auto;
      // background-color: transparent;
      margin-bottom: 10px;
      border-top: 1;
      border-bottom: 0;
      box-shadow: none;
      border-radius: 8px;
    }
  }
}
// @include media-breakpoint-up(xs) {
//   .app-toolbar {
//     body:not([data-kt-app-toolbar-fixed-mobile="true"]) & {
//       height: auto;
//       // background-color: transparent;
//       margin-bottom: 10px;
//       margin-left: 20px;
//       margin-right: 20px;
//       margin-top: 10px;
//       border-top: 0;
//       border-bottom: 0;
//       border-radius: 8px;
//       box-shadow: none;
//     }
//   }
// }
