.btn.btn-danger-mc{
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	letter-spacing: -0.34px;
	width: 70px;
    padding-left: 0px!important;
    padding-right: 0px!important;
	.icon-loading{
		display: none;
	}
	&.btn-loading{
		color: transparent!important;
		& > .icon-loading{
			display: block;
		}
	}
	&.btn-status-cs-COMPLETED{
		background-color: var(--bs-success)
	}
	&.btn-status-cs-UNANSWERED{
		background-color: var(--bs-danger)
	}
}