.table-mc{
   position: relative;
   .me-mc-100px{
    margin-right: 100px;
   }
}

.infinite-loading-outer {
	position: relative;
	width: 100%;
	height: 0px;
   display: none;
	background: rgba(48, 158, 247, 0.3);
   // transition: all 300 ease-in;
   &.is-show-loading{
      display: block;
   }
}

.infinite-loading-inner {
	position: absolute;
	width: 0px;
	height: 4px;
	left: 0px;
	background: rgba(48, 158, 247, 0.7);
	animation: 2s infinite linear loadingAnimation;
   
}

@keyframes loadingAnimation {
	0% {
		left: 0px;
		width: 0px;
	}
	
	25% {
		left: 0%;
		width: 60%;
	}
	
	70% {
		left: 60%;
		width: 40%;
	}
	
	90% {
		left: 100%;
		width: 0px;
	}
}