.hashtags-custom {
  div.ReactTags__tags {
    position: relative;
  }

  /* Styles for the input */
  div.ReactTags__tagInput {
    width: 100%;
    display: inline-block;
    margin-bottom: 5px;
  }
  div.ReactTags__tagInput input.ReactTags__tagInputField,
  div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    // height: 31px;
    // margin: 0;
    // font-size: 12px;
    // width: 100%;
    // border: 1px solid #eee;
    // padding: 0 4px;
  }

  /* Styles for selected tags */
  div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid var(--bs-primary);
    color: var(--bs-primary-inverse);
    background-color: var(--bs-primary);
    font-size: 13px;
    display: inline-block;
    padding: 1px 3px 1px 3px;
    margin: 0 5px 5px 0px;
    cursor: move;
    border-radius: 2px;
  }
  div.ReactTags__selected button.ReactTags__remove {
    color: var(--bs-primary-inverse);
    background-color: var(--bs-primary);
    margin-left: 0px;
    cursor: pointer;
    border: none;
    font-weight: 700;
    font-size: 18px !important;
    line-height: 18px !important;
  }

  /* Styles for suggestions */
  div.ReactTags__suggestions {
    position: absolute;
    z-index: 3;
    margin-top: 5px;
    border-radius: 2px;
  }
  div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    background: white;
    min-width: 200px;
    padding: 0;
  }
  div.ReactTags__suggestions li {
    border-radius: 2px;
    overflow: hidden;
    padding: 1px 10px;
    margin: 0;
    cursor: pointer;
    min-width: 200px;
    min-height: 38px;
    line-height: 36px;
  }
  div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
  }
  div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: rgba(244, 246, 249, 255);
    border-color: var(--bs-primary);
    cursor: pointer;
  }
}
