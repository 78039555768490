[data-bs-theme=dark] input.form-control, [data-bs-theme=dark] textarea.form-control {
    background-color: var(--bs-light) !important;
    border-color: var(--bs-light) !important;
    color: var(--bs-light-inverse) !important;
}

.ql-editor{
    min-height: 100px;
}

.ql-container.ql-snow, .ql-toolbar.ql-snow{
    background-color: var(--bs-gray-100);
    border-color: var(--bs-gray-100);
    color: var(--bs-gray-700);
    transition: color 0.2s ease;
}

.ql-toolbar.ql-snow {
    border-bottom: 1px solid var(--bs-gray-200);
}

[data-bs-theme=dark] .ql-container.ql-snow, 
[data-bs-theme=dark] .ql-toolbar.ql-snow {
    background-color: var(--bs-light) !important;
    border-color: var(--bs-light) !important;
    color: var(--bs-light-inverse) !important;
}