$white: #ffffff !default;

$explore-success-mc: #2F7A51;
$explore-success-mc-light: #e8fff3;
$explore-success-mc-active: #2F7A51;
$explore-success-mc-inverse: $white;

$explore-secondary-mc: #2A3042;
$explore-secondary-mc-light: #e1e3ea;
$explore-secondary-mc-active: #2A3042;
$explore-secondary-mc-inverse: $white;


$explore-danger-mc: #D70000;
$explore-danger-mc-light: #e8fff3;
$explore-danger-mc-active: #c40202;
$explore-danger-mc-inverse: $white;

.explore-btn-success-mc {
  border: 1px solid #CDCDCD;
  color: $explore-success-mc-inverse;
  background-color: $explore-success-mc;

  &:hover,
  &.active  {
    color: $explore-success-mc-inverse;
    background-color: $explore-success-mc-active;
  }
}

.explore-btn-danger-mc {
  border: 1px solid #CDCDCD;
  color: $explore-danger-mc-inverse;
  background-color: $explore-danger-mc;

  &:hover,
  &.active  {
    color: $explore-danger-mc-inverse;
    background-color: $explore-danger-mc-active;
  }
}

.explore-btn-secondary-mc {
  border: 1px solid #CDCDCD;
  color: $explore-secondary-mc-inverse;
  background-color: $explore-secondary-mc;

  &:hover,
  &.active {
    color: $explore-secondary-mc-inverse;
    background-color: $explore-secondary-mc-active;
  }
}

.explore-icon-success-mc {
  color: $explore-success-mc;
}

.explore-icon-danger-mc {
  color: $explore-danger-mc;
}

.explore-btn-border-radius-10-mc{
  border-radius: 10px;
}

.explore-btn-border-radius-40-mc{
  border-radius: 40px;
}

@media (min-width: 992px){
  .explore-btn-mc{
    padding-left: 20px!important;
    padding-right: 20px!important;
    &.btn-min-w-175px{
      min-width: 175px;
    }
  }
}