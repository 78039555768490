[data-bs-theme=dark] input.form-control {
    background-color: var(--bs-light) !important;
    border-color: var(--bs-light) !important;
    color: var(--bs-light-inverse) !important;
}
.form-range-picker-custom{
    .ant-picker-range{
        // border: 0px;
        width: 100%;
        // height: 44px;
        border-radius: 5px;
        font-weight: 600;
        // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        height: calc(1.5em + 1.55rem + 2px);
        background-color: var(--bs-gray-100);
        border-color: var(--bs-gray-100);
        color: var(--bs-gray-700);
    }
}