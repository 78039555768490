.btn.btn-cancel-mc{
	min-width: 135px;

	.icon-loading{
		display: none;
	}
	&.btn-loading{
		color: transparent!important;
		& > .icon-loading{
			display: block;
		}
	}
}
[data-bs-theme=dark] {
	.btn.btn-cancel-mc{
	}
}