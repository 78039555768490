.btn.btn-primary-mc{
	//border-radius: 10px;
	// background: #2A3042;
	// color: #CDCDCD;
	// font-size: 17px;
	// font-style: normal;
	// font-weight: 500;
	// line-height: normal;
	// letter-spacing: -0.34px;
	min-width: 135px;
	// padding: 16px 82px!important;
	&.btn-w-long{
		// padding: 16px 45px!important;
	}
	&:hover,
	&:focus,
	&:active {
		color: #FFF;
		background: #242939;
	}
	.icon-loading{
		display: none;
	}
	&.btn-loading{
		color: transparent!important;
		& > .icon-loading{
			display: block;
		}
	}
}
[data-bs-theme=dark] {
	.btn.btn-primary-mc{
		.icon-loading.mc-icon-spinner-ios > div{
			background-color: #242939!important;
		}
	}
}