.noti-modal{
    .modal-content{
        // border-radius: 30px;
        background: #FFF;
        box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.10);
        &:not(.shadow-none){
            margin: 0 30px;
        }
    }
    .modal-body{
        padding: 20px;
    }
    .modal-content-text{
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: -0.3px;
        margin-bottom: 15px;
    }
}
[data-bs-theme=dark] {
    .noti-modal{
        .modal-content{
            background: #565674;
        }
    }
}

.btn.btn-delete-mc{
	//border-radius: 10px;
	// background: #2A3042;
	// color: #CDCDCD;
	// font-size: 17px;
	// font-style: normal;
	// font-weight: 500;
	// line-height: normal;
	// letter-spacing: -0.34px;
	min-width: 80px;
	// padding: 16px 82px!important;
	&.btn-w-long{
		// padding: 16px 45px!important;
	}
	&:hover,
	&:focus,
	&:active {
		color: #FFF;
		background: #242939;
	}
	.icon-loading{
		display: none;
	}
	&.btn-loading{
		color: transparent!important;
		& > .icon-loading{
			display: block;
		}
	}
}
[data-bs-theme=dark] {
	.btn.btn-delete-mc{
		.icon-loading.mc-icon-spinner-ios > div{
			background-color: #242939!important;
		}
	}
}