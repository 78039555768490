.react-select-container{
	margin-left: 1px;
	.react-select__control.react-select__control--is-focused{
		background-color: var(--bs-gray-200);
		border-color: var(--bs-gray-200);
		color: var(--bs-gray-700);
		transition: color 0.2s ease;
		padding: 1px 4px 1px 4px;
		border-radius: 0.475rem;
		outline: 0;
		box-shadow: false, 0 0 0 0.25rem rgba(var(--bs-component-active-bg), 0.25);
	}
	& .react-select__control {
		background-color: var(--bs-gray-100);
		border-color: var(--bs-gray-100);
		color: var(--bs-gray-700);
		transition: color 0.2s ease;
		padding: 1px 4px 1px 4px;
		border-radius: 0.475rem;
		&:hover{
			background-color: var(--bs-gray-100);
			border-color: var(--bs-gray-100);
			color: var(--bs-gray-700);
			transition: color 0.2s ease;
			padding: 1px 4px 1px 4px;
			border-radius: 0.475rem;
		}
		& .react-select__single-value{
			color: var(--bs-gray-700);
			font-size: 1.1rem;
			font-weight: 500;
			line-height: 1.5;
		}
		& .react-select__multi-value{
			// box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
			border-color: var(--bs-primary);
			background-color: var(--bs-primary);
			& .react-select__multi-value__label{
				font-size: 13px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				padding: 4px 0px 4px 10px;
				color: var(--bs-primary-inverse);
			}
			& .react-select__multi-value__remove{
				font-size: 13px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				padding: 0px 3px 0px 0px;
				color: var(--bs-primary-inverse);
				&:hover{
					background-color: var(--bs-primary);
				}
				 & svg{
					width: 19px;
    				height: 19px;
				 }
			}
			
		}
		& .react-select__clear-indicator{
			display: none;
		}
		& .react-select__indicator-separator{
			display: none;
		}
		& .react-select__dropdown-indicator{
			padding: 14px 10px 13px 10px;
		}
	}
	
}
[data-bs-theme=dark] {
	.react-select-container{
		& .react-select__control {
			background-color: var(--bs-light) !important;
			border-color: var(--bs-light) !important;
			color: var(--bs-light-inverse) !important;
			padding: 1px 4px 1px 4px;
			border-radius: 0.475rem;
			& .react-select__single-value{
				color: var(--bs-light-inverse) !important;
			}
		}
		& .react-select__menu-list{
			color: var(--bs-light-inverse) !important;
		}
	}
}