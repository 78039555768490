.pagination-mc{
    .active > .page-link {
        background-color: transparent;
        font-weight: 900;
        font-size: 1.3rem;
        color: var(--bs-pagination-color);
    }
    .page-item:hover:not(.active):not(.offset):not(.disabled) .page-link {
        color: var(--bs-pagination-color);
    }
    .page-item:focus:not(.active) .page-link {
        color: var(--bs-pagination-color);
    }
}
[data-bs-theme=dark] {
    .pagination-mc{
        .active > .page-link {
            background-color: transparent;
            font-weight: 900;
            font-size: 1.3rem;
            color: var(--bs-pagination-active-color);
        }
        .page-item:hover:not(.active):not(.offset):not(.disabled) .page-link {
            color: var(--bs-pagination-active-color);
        }
        .page-link:focus {
            color: var(--bs-pagination-active-color);
        }
    }
}