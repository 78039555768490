.table-background-gray{
  // background-color: transparent;
  border-radius: 0.625rem;
  overflow: hidden;
  & thead{
    // background: #A6B0CF;
    // color: #fff;
    // background: transparent;
    // color: #fff;
    // position: relative;
    // z-index: 1;
    // &::after{
    //   content: '';
    //   position: absolute;
    //   left: 0;
    //   right: 0;
    //   bottom: 5px;
    //   top: 0;
    //   background: #A6B0CF;
    //   z-index: -1;
    //   border-radius: 5px;
    //   overflow: hidden;
    // }
    // & th{
    //   padding-bottom: calc(1rem + 5px)!important;
    // }
  }
  & tbody{
    // tr {
    //   background: transparent;
    //   position: relative;
    //   z-index: 1;
    //   border-radius: 8px;
    //   overflow: hidden;
    //   &::after{
    //     content: '';
    //     position: absolute;
    //     left: 0px;
    //     right: 0px;
    //     bottom: 5px;
    //     top: 0px;
    //     background: #fff;
    //     z-index: -1;
    //     border-radius : 5px;
    //     box-shadow: 2px 4px 4px -2px rgba(0, 0, 0, 0.25);
    //   }
    //   & td{
    //     padding-bottom: calc(1rem + 7px)!important;
    //   }
    // }
  }
  & .table-responsive{
    // border-radius: var(--bs-card-border-radius);
  }
}
.badge-light-dark-custom{
  border-radius: 40px;
  border: 1px solid #CDCDCD;
  background: #FFF;
  align-items: center;
}
[data-bs-theme=dark] {
  .badge-light-dark-custom{
    color: #3b3b58;
  }
  .table-background-gray{
    & thead{
      // background: #2a2a3c;
      // &::after{
      //   background: #2a2a3c;
      // }
    }
    & tbody{
      tr {
        // &::after{
        //   content: '';
        //   background: #27273a;
        // }
      }
    }
  }
}