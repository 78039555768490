div.mc-icon-spinner-ios {
	position: relative;
    width: 21px;
    height: 21px;
    left: 50%;
    transform: translate(-50%, 0%);
    padding: 0px;
    border-radius: 20px;
    text-align: center;
    // background: rgba(0, 0, 0, 0.4);
  }

  div.mc-icon-spinner-ios div {
    width: 12%;
    height: 30%;
    background: #ffffff;
    position: absolute;
    left: 45%;
    top: 35%;
    opacity: 0;
    border-radius: 20px;
    box-shadow: 0 0 3px rgba(0,0,0,0.2);
    animation: fade 1s linear infinite;
  }
  
  @keyframes fade {
    from {opacity: 1;}
    to {opacity: 0.25;}
  }
  
  div.mc-icon-spinner-ios div.mc-icon-spinner-ios-bar1{
    transform:rotate(0deg) translate(0, -130%);
    animation-delay: 0s;
  }    
  
  div.mc-icon-spinner-ios div.mc-icon-spinner-ios-bar2 {
	  transform:rotate(30deg) translate(0, -130%); 
	  animation-delay: -0.9167s;
  }
  
  div.mc-icon-spinner-ios div.mc-icon-spinner-ios-bar3 {
	  transform:rotate(60deg) translate(0, -130%); 
	  animation-delay: -0.833s;
  }
  div.mc-icon-spinner-ios div.mc-icon-spinner-ios-bar4 {
	  transform:rotate(90deg) translate(0, -130%); 
	  animation-delay: -0.7497s;
  }
  div.mc-icon-spinner-ios div.mc-icon-spinner-ios-bar5 {
	  transform:rotate(120deg) translate(0, -130%); 
	  animation-delay: -0.667s;
  }
  div.mc-icon-spinner-ios div.mc-icon-spinner-ios-bar6 {
	  transform:rotate(150deg) translate(0, -130%); 
	  animation-delay: -0.5837s;
  }
  div.mc-icon-spinner-ios div.mc-icon-spinner-ios-bar7 {
	  transform:rotate(180deg) translate(0, -130%); 
	  animation-delay: -0.5s;
  }
  div.mc-icon-spinner-ios div.mc-icon-spinner-ios-bar8 {
	  transform:rotate(210deg) translate(0, -130%); 
	  animation-delay: -0.4167s;
  }
  div.mc-icon-spinner-ios div.mc-icon-spinner-ios-bar9 {
	  transform:rotate(240deg) translate(0, -130%); 
	  animation-delay: -0.333s;
  }
  div.mc-icon-spinner-ios div.mc-icon-spinner-ios-bar10 {
	  transform:rotate(270deg) translate(0, -130%); 
	  animation-delay: -0.2497s;
  }
  div.mc-icon-spinner-ios div.mc-icon-spinner-ios-bar11 {
	  transform:rotate(300deg) translate(0, -130%); 
	  animation-delay: -0.167s;
  }
  div.mc-icon-spinner-ios div.mc-icon-spinner-ios-bar12 {
	  transform:rotate(330deg) translate(0, -130%); 
	  animation-delay: -0.0833s;
  }
[data-bs-theme=dark] {
    button > .div.mc-icon-spinner-ios div{
      background: #8e8e8e!important;
    }
}